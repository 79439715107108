<template>
  <div class="flex overflow-hidden relative">
    <div class="py-8 pr-7 w-full">
      <div class="flex justify-between items-center">
        <h1 class="text-2xl font-bold">System settings</h1>
        <button
          @click="addSettings = true"
          class="bg-primary py-4 px-7 rounded-xl shadow-lg flex items-center font-bold text-white"
        >
          <plus class="mr-5" />
          Add Settings
        </button>
      </div>
      <settings-table :settings="settings" @edit="editSetting" />
    </div>
    <add-new-settings-modal
      v-if="addSettings"
      @done="fetchAllSettings()"
      @close="
        addSettings = false;
        action = 'Add';
        newSettings = {};
      "
      :newSettings="newSettings"
      :action="action"
    />
  </div>
</template>

<script>
import { fetchBranchSettings } from "@/services/settings";

export default {
  name: "SystemSettingsView",
  components: {
    plus: () => import("@/assets/icons/PlusIcon.vue"),
    AddNewSettingsModal: () =>
      import("@/components/Settings/AddNewSettingsModal.vue"),
    SettingsTable: () => import("@/components/Settings/SettingsTable.vue"),
  },
  data() {
    return {
      settings: [],
      addSettings: false,
      newSettings: {},
      action: "Add",
    };
  },
  methods: {
    editSetting(item) {
      this.newSettings = item;
      this.action = "Edit";
      this.addSettings = true;
    },
    async fetchAllSettings() {
      this.action = "Add";
      this.addSettings = false;
      this.newSettings = {};
      try {
        const response = await fetchBranchSettings();
        if (typeof response.data.settings === "object") {
          this.settings.push(response.data.settings);
        } else {
          this.settings = response.data.settings;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.fetchAllSettings();
  },
};
</script>
